import * as React from "react";
import Symbol from "./Symbol";

type Props = {
  className?: string;
  style?: object;
  value: number;
};

class Stars extends React.Component<Props> {
  public state = {
    arr: [],
  };

  public render() {
    const { className, style } = this.props;
    const symbolNodes = [];
    const empty = [].concat(<img src="../../../empty-star.svg" />);
    const full = [].concat(<img src="../../../full-star.svg" />);

    // The value that will be used as base for calculating how to render the symbols
    const renderedValue = this.props.value;

    // The amount of full symbols
    const fullSymbols = Math.floor(renderedValue);

    for (let i = 0; i < 5; i++) {
      let percent;
      // Calculate each symbol's fullness percentage
      if (i - fullSymbols < 0) {
        percent = 100;
      } else if (i - fullSymbols === 0) {
        percent = (renderedValue - i) * 100;
      } else {
        percent = 0;
      }

      symbolNodes.push(
        <Symbol
          key={i}
          index={i}
          inactiveIcon={empty[i % empty.length]}
          activeIcon={full[i % full.length]}
          percent={percent}
        />
      );
    }

    return (
      <span style={{ ...style, display: "inline-block" }} className={className}>
        {symbolNodes}
      </span>
    );
  }
}

export default Stars;
