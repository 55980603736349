import * as React from "react";
import Typography from "src/components/Typography";
import styles from "./ArrowButton.module.css";

type Props = {
  text?: string;
  hideArrowOnMobile?: boolean;
};

const ArrowButton = (props: Props) => {
  const { hideArrowOnMobile } = props;
  return (
    <div className={styles.buttonWrapper}>
      <Typography type="biggerText">{props.text || "СМОТРЕТЬ"}</Typography>
      <div
        className={[
          styles.arrowWrapper,
          hideArrowOnMobile && styles.hideArrowOnMobile,
        ].join(" ")}
      >
        <img src="/arrow.svg" alt="стрелка" />
      </div>
    </div>
  );
};

export default ArrowButton;
