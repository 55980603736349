import React from "react";

// Return the corresponding React node for an icon.
const _iconNode = (icon) => {
  // If it is already a React Element just return it.
  if (React.isValidElement(icon)) {
    return icon;
  }
  // If it is an object, try to use it as a CSS style object.
  if (typeof icon === "object" && icon !== null) {
    return <span style={icon} />;
  }
  // If it is a string, use it as class names.
  if (Object.prototype.toString.call(icon) === "[object String]") {
    return <span className={icon} />;
  }
};

interface Props {
  index: number;
  inactiveIcon: React.ReactNode;
  activeIcon: React.ReactNode;
  percent: number;
}

class RatingSymbol extends React.PureComponent<Props> {
  public render() {
    const { inactiveIcon, activeIcon, percent } = this.props;
    const backgroundNode = _iconNode(inactiveIcon);
    const showbgIcon = percent < 100;
    const iconNode = _iconNode(activeIcon);

    return (
      <span
        style={{
          display: "inline-block",
          position: "relative",
        }}
      >
        <span
          style={{
            display: "inline-block",
            position: "absolute",
            overflow: "hidden",
            top: 0,
            width: `${percent}%`,
            zIndex: 100,
          }}
        >
          {iconNode}
        </span>
        <span
          style={
            showbgIcon
              ? {}
              : {
                  visibility: "hidden",
                }
          }
        >
          {backgroundNode}
        </span>
      </span>
    );
  }
}

export default RatingSymbol;
