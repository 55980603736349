import * as React from "react";
import Stars from "src/components/Stars";
import Typography from "src/components/Typography";
import { VideoRating } from "src/types";
import styles from "./DisplayRating.module.css";

interface Props {
  rating: VideoRating;
}

export const DisplayRating: React.SFC<Props> = (props) => {
  const { rating } = props;
  return (
    <div className={styles.starsRatingWrapper}>
      <Stars value={rating.value / rating.count / 2} />
      <Typography type="boldText" className={styles.ratingResult}>
        {(rating.value / rating.count)?.toFixed(1)}
      </Typography>
    </div>
  );
};

export default DisplayRating;
