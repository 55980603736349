import * as React from "react";
import Typography from "src/components/Typography";
import styles from "./PageHeader.module.css";

const PageHeader: React.SFC<{ text: string }> = (props) => {
  return (
    <div className={styles.headerWrapper}>
      <div className={styles.line} />
      <div className={styles.content}>
        <Typography type="h1">
          <h1>{props.text}</h1>
        </Typography>
        <div className={styles.desktopChildren}>{props.children}</div>
      </div>
      <div className={styles.line} />
      <div className={styles.mobileChildren}>{props.children}</div>
    </div>
  );
};

export default PageHeader;
